#actions_images {
    .modal-dialog {
        width : 940px;
    }
    .modal-content {
        position : relative;
        background-color: transparent;
        border: 0 none;
        padding : 40px 120px;
        @include lib-css(border-radius, 0, 1);
        @include lib-css(box-shadow, none, 1);
        &:before,
        &:after {
            content: '';
            display : block;
            position : absolute;
            background-repeat: no-repeat;
        }
        &:before {
            width : 81px;
            height : 84px;
            background-image: url('../images/act/modals/dragonfly-left.png');
            left : 0;
            top : 0;
        }
        &:after {
            width : 90px;
            height : 107px;
            background-image: url('../images/act/modals/dragonfly-right.png');
            right : 0;
            top : 70px;
        }
    }
    .banner {
        position : relative;
        overflow : hidden;
        border: 4px solid $color-white;
        &:before {
            display        : block;
            content        : '';
            padding-bottom : percentage(431px/692px);
        }
        img {
            position  : absolute;
            @include center-all();
            min-width : 100%;
        }
    }
    .carousel-container {
        margin : 53px -10px 0;
    }
    .item-wrapper {
        padding : 0 10px;
        .img {
            border: 3px solid $color-white;
            position : relative;
            overflow: hidden;
            cursor: pointer;
            &:before {
                display        : block;
                content        : '';
                padding-bottom : percentage(431px/692px);
            }
            img {
                position  : absolute;
                @include center-all();
                min-width : 100%;
            }
        }
    }
    .owl-nav {
        > div {
            color: $color-orange;
            font-size : 20px;
            font-weight : 100;
            @include center-y();
        }
        .owl-prev {
            left : -8px;
        }
        .owl-next {
            right : -8px;
        }
    }

    @include breakpoint(tablet) {
        .modal-dialog {
            width : 750px;
        }
        .modal-content {
            padding : 30px 60px;
            &:before,
            &:after {
                background-size: 100% auto;
            }
            &:before {
                width : 50px;
            }
            &:after {
                width : 50px;
                top : 70px;
            }
        }
    }

    @include breakpoint(mobile) {
        .modal-dialog {
            width : auto;
        }
        .modal-content {
            padding : 30px 0;
            &:before,
            &:after {
                display : none;
            }
        }
    }
}
.modal-backdrop.in {
    @include opacity(0.85);
}

#actions_special {
    .modal-dialog {
        width: 940px;
        margin-top: 5cm;
    }

    .modal-content {
        background-image: url('../images/section-5-bg-full-height.png');
    }
    .banner {
        min-height: 350px;
        padding: 20px;
    }

    @include breakpoint(tablet) {
        .modal-dialog {
            width : 750px;
            margin-top: 5cm;
        }
        .modal-content {
            padding : 30px 60px;
            &:before,
            &:after {
                background-size: 100% auto;
            }
            &:before {
                width : 50px;
            }
            &:after {
                width : 50px;
                top : 70px;
            }
        }
    }

    @include breakpoint(mobile) {
        .modal-dialog {
            width : auto;
            margin-top: auto;
        }
        .modal-content {
            padding : 30px 0;
            &:before,
            &:after {
                display : none;
            }
        }
    }
}

.close-modal {
    width : 50px;
    height : 50px;
    right : 0;
    position : absolute;
    top : 0;
    margin: 0;
    line-height: 45px;
    text-align: center;
    font-size: 30px;
    color: $color-orange;
    cursor : pointer;
}