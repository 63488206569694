@font-face {
    font-family:'UTMAvo';
    src: url('../fonts/utmavo/UTMAvo-Regular.eot');
    src: url('../fonts/utmavo/UTMAvo-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/utmavo/UTMAvo-Regular.woff') format('woff'),
    url('../fonts/utmavo/UTMAvo-Regular.ttf') format('truetype'),
    url('../fonts/utmavo/UTMAvo-Regular.otf') format('opentype'),
    url('../fonts/utmavo/UTMAvo-Regular.svg#UTMAvo-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2026;
}

@font-face {
    font-family:'UTMAvo';
    src: url('../fonts/utmavo/UTMAvo-Italic.eot');
    src: url('../fonts/utmavo/UTMAvo-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/utmavo/UTMAvo-Italic.woff') format('woff'),
    url('../fonts/utmavo/UTMAvo-Italic.ttf') format('truetype'),
    url('../fonts/utmavo/UTMAvo-Italic.otf') format('opentype'),
    url('../fonts/utmavo/UTMAvo-Italic.svg#UTMAvo-Italic') format('svg');
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-2026;
}

@font-face {
    font-family:'UTMAvo';
    src: url('../fonts/utmavo/UTMAvoBold-Regular.eot');
    src: url('../fonts/utmavo/UTMAvoBold-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/utmavo/UTMAvoBold-Regular.woff') format('woff'),
    url('../fonts/utmavo/UTMAvoBold-Regular.ttf') format('truetype'),
    url('../fonts/utmavo/UTMAvoBold-Regular.otf') format('opentype'),
    url('../fonts/utmavo/UTMAvoBold-Regular.svg#UTMAvoBold-Regular') format('svg');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2026;
}

@font-face {
    font-family:'UTMAvo';
    src: url('../fonts/utmavo/UTMAvo-BoldItalic.eot');
    src: url('../fonts/utmavo/UTMAvo-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/utmavo/UTMAvo-BoldItalic.woff') format('woff'),
    url('../fonts/utmavo/UTMAvo-BoldItalic.ttf') format('truetype'),
    url('../fonts/utmavo/UTMAvo-BoldItalic.otf') format('opentype'),
    url('../fonts/utmavo/UTMAvo-BoldItalic.svg#UTMAvo-BoldItalic') format('svg');
    font-weight: 700;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-2026;
}

$font-base: 'UTMAvo', Helvetica, Arial, sans-serif;
