$color-white: #fff;
$color-black: #35424f;

$color-green: #17cf8e;
$color-green-sharp: #29a2c1;
$color-green-haze: #8dd2c8;

$color-red: #f15a29;
$color-red-intense: #ff7861;

$color-yellow: #f1c15e;
$color-yellow-crusta: #ffc949;
$color-yellow-casablanca: #ffb76d;

$color-orange: #f68a1f;

$color-blue: #0f87f6;
$color-blue-sharp: #29a2c1;

$color-grey: #e0e0e0;
$color-light-grey: #f4f4f4;
$color-darker-grey: #c9c9c9;
$color-dark-grey: #333;

$color-purple: #472062;
