.owl-theme {
    .owl-nav {
        margin : -30px 0 0;
        position : absolute;
        left : -60px;
        right : -60px;
        @include center-y();
        > [class*="owl-"] {
            background-color: transparent;
            color: $color-orange;
            font-size : 30px;
            margin : 0;
            @include lib-css(border-radius, 0, 1);
            &:hover {
                background-color: transparent;
                color: lighten($color-orange, 10%);
            }
            &.owl-prev {
                float : left;
            }
            &.owl-next {
                float : right;
            }
        }
    }
    .owl-dots {
        margin-top : 30px;
        .owl-dot {
            &.active {
                span {
                    background-color: $color-orange;
                }
            }
            span {
                width : 22px;
                height : 22px;
                border: 1px solid $color-orange;
                background-color: transparent;
                margin : 0 3px;
                @include lib-css(transition, all 0.3s ease-in-out, 1);
                &:hover {
                    background-color: $color-orange;
                }
            }
        }
    }
}
