@mixin media-min-width($_screen-width) {
    @media (min-width: $_screen-width){
        @content;
    }
}

@mixin media-max-width($_screen-width) {
    @media (max-width: $_screen-width){
        @content;
    }
}

@mixin media-min-max-width($_screen-min-width, $_screen-max-width) {
    @media (min-width : $_screen-min-width) and (max-width : $_screen-max-width) {
        @content;
    }
}

@mixin breakpoint($_point) {
    @if ($_point == very-large-desktop) {
        @include media-min-width($screen-xxlg-min) {
            @content;
        }
    } @else if ($_point == large-desktop) {
        @include media-min-max-width($screen-xlg-min, $screen-xlg-max) {
            @content;
        }
    } @else if ($_point == desktop) {
        @include media-min-max-width($screen-lg-min, $screen-lg-max) {
            @content;
        }
    } @else if ($_point == small-desktop) {
        @include media-min-max-width($screen-md-min, $screen-md-max) {
            @content;
        }
    } @else if ($_point == tablet) {
        @include media-min-max-width($screen-sm-min, $screen-sm-max) {
            @content;
        }
    } @else if ($_point == mobile) {
        @include media-max-width($screen-xs-max) {
            @content;
        }
    }
}

@include breakpoint(very-large-desktop) {

}

@include breakpoint(large-desktop) {

}

@include breakpoint(desktop) {

}

@include breakpoint(small-desktop) {

}

@include breakpoint(tablet) {

}

@include breakpoint(mobile) {

}
