body {
    font-family : $font-base;
    color       : $color-black;
    * {
        @include lib-css(border-radius, 0, 1);
    }
}

h1,
.h1 {
    font-size : 36px;
    font-weight : 700;
    margin-bottom : 20px;
}

h2,
.h2 {
    font-size   : 30px;
    font-weight : 700;
}

h3,
.h3 {
    font-size   : 18px;
    font-weight : 700;
}

p {
    font-size : 15px;
    line-height : 1.7;
}

a {
    color: $color-black;
    cursor: pointer;
    &:active,
    &:focus,
    &:hover {
        color: $color-red;
    }
}

/*Font style*/
.uppercase {
    text-transform : uppercase !important;
}
.capitalize {
    text-transform : capitalize !important;
}
.lowercase {
    text-transform : lowercase !important;
}
.bold {
    font-weight : 700 !important;
}
.lighter {
    font-weight : 300 !important;
}
.normal {
    font-weight : 400 !important;
}

/*Colors*/
.white {
    color: $color-white;
}
.black {
    color: $color-black;
}
.green {
    color : $color-green;
}
.green-sharp {
    color : $color-green-sharp;
}
.green-haze {
    color : $color-green-haze;
}
.red {
    color : $color-red;
}
.red-intense {
    color : $color-red-intense;
}
.yellow {
    color : $color-yellow;
}
.yellow-crusta {
    color : $color-yellow-crusta;
}
.yellow-casablanca {
    color : $color-yellow-casablanca;
}
.orange {
    color : $color-orange;
}
.blue {
    color : $color-blue;
}
.blue-sharp {
    color : $color-blue-sharp;
}
.grey {
    color : $color-grey;
}
.light-grey {
    color : $color-light-grey;
}
.dark-grey {
    color : $color-dark-grey;
}
.purple {
    color : $color-purple;
}

/*Responsive*/
@include media-max-width($screen-lg-max) {
    h1,
    .h1 {
        font-size : 30px;
    }

    h2,
    .h2 {
        font-size   : 25px;
    }

    h3,
    .h3 {
        font-size   : 16px;
    }

    p {
        font-size : 14px;
    }
}

@include media-max-width($screen-sm-max) {
    h1,
    .h1 {
        font-size : 25px;
    }

    h2,
    .h2 {
        font-size   : 20px;
    }

    h3,
    .h3 {
        font-size   : 15px;
    }

    p {
        font-size : 13px;
    }
}
