.nav {
    @include clearfix;
    > li {
        cursor : pointer;
        &.active > a {
            border-color : $color-red;
            color        : $color-red;
            @include lib-css(transition, all 0.3s ease-in-out, 1);
        }
        > a {
            vertical-align : middle;
            text-align     : center;
            width          : 100%;
            display        : table;
            border         : 1px solid transparent;
            font-size      : 16px;
            &:active,
            &:focus,
            &:hover {
                background-color : transparent;
            }
            span {
                display      : inline-block;
                text-align   : left;
                padding-left : 40px;
                position     : relative;
                &:before {
                    font-size        : 0;
                    display          : table-cell;
                    content          : '';
                    width            : 35px;
                    height           : 35px;
                    background-image : url('../images/dragonfly-icon.png');
                    left             : 0;
                    background-size: 100% 100%;
                    @include center-y();
                }
            }
        }
    }
}

.tab-content {
    margin-bottom : 50px;
}

.tab-pane {
    padding-top: 150px;

    .h2 {
        margin-bottom : 20px;
    }
}

@include media-max-width($screen-xs-max) {
    .tab-pane {
        padding-top: 80px;
    }
}

@include media-max-width($screen-lg-max) {
    .tab-content {
        margin-bottom : 10px;
    }
    .nav > li > a {
        font-size : 14px;
        padding : 10px 5px;
        span {
            padding-left : 30px;
            &:before {
                height : 25px;
                width : 25px;
            }
        }
    }
}
