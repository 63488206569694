.btn {
    border      : 0 none;
    font-size   : 18px;
    line-height : 1;
    color       : $color-white;
    padding     : 21px 30px;
    @include lib-css(box-shadow, none, 1);
    @include lib-css(border-radius, 0, 1);
    @include lib-css(transition, all 0.3s ease-in-out, 1);
    &:hover {
        color : $color-white;
    }
    &.green {
        background-color : $color-green;
        &:hover {
            background-color : darken($color-green, 5%);
        }
        &:active {
            background-color : darken($color-green, 10%);
        }
    }
    &.green-sharp {
        background-color : $color-green-sharp;
        &:hover {
            background-color : darken($color-green-sharp, 5%);
        }
        &:active {
            background-color : darken($color-green-sharp, 10%);
        }
    }
    &.green-haze {
        background-color : $color-green-haze;
        &:hover {
            background-color : darken($color-green-haze, 5%);
        }
        &:active {
            background-color : darken($color-green-haze, 10%);
        }
    }
    &.red {
        background-color : $color-red;
        &:hover {
            background-color : darken($color-red, 5%);
        }
        &:active {
            background-color : darken($color-red, 10%);
        }
    }
    &.red-intense {
        background-color : $color-red-intense;
        &:hover {
            background-color : darken($color-red-intense, 5%);
        }
        &:active {
            background-color : darken($color-red-intense, 10%);
        }
    }
    &.yellow {
        background-color : $color-yellow;
        &:hover {
            background-color : darken($color-yellow, 5%);
        }
        &:active {
            background-color : darken($color-yellow, 10%);
        }
    }
    &.yellow-crusta {
        background-color : $color-yellow-crusta;
        &:hover {
            background-color : darken($color-yellow-crusta, 5%);
        }
        &:active {
            background-color : darken($color-yellow-crusta, 10%);
        }
    }
    &.yellow-casablanca {
        background-color : $color-yellow-casablanca;
        &:hover {
            background-color : darken($color-yellow-casablanca, 5%);
        }
        &:active {
            background-color : darken($color-yellow-casablanca, 10%);
        }
    }
    &.blue {
        background-color : $color-blue;
        &:hover {
            background-color : darken($color-blue, 5%);
        }
        &:active {
            background-color : darken($color-blue, 10%);
        }
    }
    &.blue-sharp {
        background-color : $color-blue-sharp;
        &:hover {
            background-color : darken($color-blue-sharp, 5%);
        }
        &:active {
            background-color : darken($color-blue-sharp, 10%);
        }
    }
    &.grey {
        background-color : $color-grey;
        color            : $color-black;
        &:hover {
            background-color : darken($color-grey, 5%);
        }
        &:active {
            background-color : darken($color-grey, 10%);
        }
    }
    &.light-grey {
        background-color : $color-light-grey;
        color            : $color-black;
        &:hover {
            background-color : darken($color-light-grey, 5%);
            color            : $color-black;
        }
        &:active {
            background-color : darken($color-light-grey, 10%);
            color            : $color-black;
        }
    }
    &.dark-grey {
        background-color : $color-dark-grey;
        &:hover {
            background-color : darken($color-dark-grey, 5%);
            color            : $color-black;
        }
        &:active {
            background-color : darken($color-dark-grey, 10%);
            color            : $color-black;
        }
    }
    &.purple {
        background-color : $color-purple;
        &:hover {
            background-color : darken($color-purple, 5%);
        }
        &:active {
            background-color : darken($color-purple, 10%);
        }
    }
}

/*Size*/
.btn-lg {
    font-size : 18px;
}

/*Type*/
.btn-circle {
    @include lib-css(border-radius, 100px, 1);
}

.btn-outline {
    border           : 4px solid $color-grey;
    background-color : $color-white !important;
    color            : $color-grey !important;
    padding-top      : 10px;
    padding-bottom   : 10px;
    &:hover {
        color : $color-white !important;
    }
    &.green {
        &:hover {
            background-color : $color-green !important;
            border-color     : $color-green !important;
        }
        &:active {
            background-color : darken($color-green, 10%);
            border-color     : darken($color-green, 10%);
        }
    }
    &.green-sharp {
        &:hover {
            background-color : $color-green-sharp !important;
            border-color     : $color-green-sharp !important;
        }
        &:active {
            background-color : darken($color-green-sharp, 10%);
            border-color     : darken($color-green-sharp, 10%);
        }
    }
    &.green-haze {
        &:hover {
            background-color : $color-green-haze !important;
            border-color     : $color-green-haze !important;
        }
        &:active {
            background-color : darken($color-green-haze, 10%);
            border-color     : darken($color-green-haze, 10%);
        }
    }
    &.red {
        &:hover {
            background-color : $color-red !important;
            border-color     : $color-red !important;
        }
        &:active {
            background-color : darken($color-red, 10%);
            border-color     : darken($color-red, 10%);
        }
    }
    &.red-intense {
        &:hover {
            background-color : $color-red-intense !important;
            border-color     : $color-red-intense !important;
        }
        &:active {
            background-color : darken($color-red-intense, 10%);
            border-color     : darken($color-red-intense, 10%);
        }
    }
    &.yellow {
        &:hover {
            background-color : $color-yellow !important;
            border-color     : $color-yellow !important;
        }
        &:active {
            background-color : darken($color-yellow, 10%);
            border-color     : darken($color-yellow, 10%);
        }
    }
    &.yellow-crusta {
        &:hover {
            background-color : $color-yellow-crusta !important;
            border-color     : $color-yellow-crusta !important;
        }
        &:active {
            background-color : darken($color-yellow-crusta, 10%);
            border-color     : darken($color-yellow-crusta, 10%);
        }
    }
    &.yellow-casablanca {
        &:hover {
            background-color : $color-yellow-casablanca !important;
            border-color     : $color-yellow-casablanca !important;
        }
        &:active {
            background-color : darken($color-yellow-casablanca, 10%);
            border-color     : darken($color-yellow-casablanca, 10%);
        }
    }
    &.blue {
        &:hover {
            background-color : $color-blue !important;
            border-color     : $color-blue !important;
        }
        &:active {
            background-color : darken($color-blue, 10%);
            border-color     : darken($color-blue, 10%);
        }
    }
    &.blue-sharp {
        &:hover {
            background-color : $color-blue-sharp !important;
            border-color     : $color-blue-sharp !important;
        }
        &:active {
            background-color : darken($color-blue-sharp, 10%);
            border-color     : darken($color-blue-sharp, 10%);
        }
    }
    &.grey {
        &:hover,
        &:active {
            color : $color-black !important;
        }
        &:hover {
            background-color : $color-grey !important;
            border-color     : $color-grey !important;
        }
        &:active {
            background-color : darken($color-grey, 10%);
            border-color     : darken($color-grey, 10%);
        }
    }
    &.light-grey {
        &:hover,
        &:active {
            color : $color-black !important;
        }
        &:hover {
            background-color : $color-light-grey !important;
            border-color     : $color-light-grey !important;
        }
        &:active {
            background-color : darken($color-light-grey, 10%);
            border-color     : darken($color-light-grey, 10%);
        }
    }
    &.dark-grey {
        &:hover {
            background-color : $color-dark-grey !important;
            border-color     : $color-dark-grey !important;
        }
        &:active {
            background-color : darken($color-dark-grey, 10%);
            border-color     : darken($color-dark-grey, 10%);
        }
    }
    &.purple {
        &:hover {
            background-color : $color-purple !important;
            border-color     : $color-purple !important;
        }
        &:active {
            background-color : darken($color-purple, 10%);
            border-color     : darken($color-purple, 10%);
        }
    }
}
