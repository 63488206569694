.site-header {
    position : fixed;
    top : 0;
    left : 0;
    width : 100%;
    z-index : 100;
    border-bottom: 1px solid transparent;
    @include lib-css(transition, all 0.3s ease-in-out, 1);
    .small-header & {
        background-color: rgba($color-white, 1);
    }
}

.site_top_panel .cws_social_links:after,
.site_top_panel .site_top_panel_toggle:before,
.site_top_panel .site_top_panel_toggle:after
{
    background-color: #f15a29;
}

.site_top_panel .links .solienlac,
.site_top_panel .cws_social_links .cws_social_link,
.site_top_panel #inks .search_icon,
.site_top_panel .mini-cart{
    color: #f15a29;
}

.site_top_panel{
    /*box-shadow: 0 3px 3px rgba(0,0,0,0.1);*/
    z-index: 1001;
}
.site_top_panel.wave{
    /*box-shadow: 0 10px 20px rgba(0,0,0,0.1);*/
}
.site_top_panel:not(.wave):after{
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: -4px;
    left: 0;
    background: #fff;
}
.site_top_panel  .top_half_sin_wrapper{
    top: calc(100% - 1px);
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 8px;
    overflow: hidden;
}
.site_top_panel .top_half_sin{
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    top: 0;
    position: absolute;
    z-index: 2;
}
.site_top_panel.slider{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);

    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
}
.site_top_panel.slider.active{
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    background: transparent;
}
.site_top_panel .site_top_panel_toggle{
    position: absolute;
    top: 100%;
    right: 15px;
    width: 31px;
    height: 25px;
    background: #fff;
    box-shadow: 0 10px 15px rgba(0,0,0,0.1);
    border-bottom-right-radius: 9px;
    border-bottom-left-radius: 9px;
    cursor: pointer;
    z-index: 1;
}
.site_top_panel .site_top_panel_toggle:before,
.site_top_panel .site_top_panel_toggle:after{
    content: '';
    position: absolute;
    top: 11px;
    left: 9px;
    width: 13px;
    height: 3px;
}
.site_top_panel .site_top_panel_toggle:after{
    transform: rotate(90deg);
}
.site_top_panel #top_panel_text{
    padding: 4px 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
/*.site_top_panel #top_panel_text a:hover,
.site_top_panel #top_panel_text a{
    color: inherit;
}*/
.site_top_panel #top_panel_text i.fa{
    padding-right: 8px;
    padding-left: 20px;
}
.site_top_panel .row_text_search{
    position: relative;
    z-index: 1;
}
.site_top_panel .container{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -moz-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    z-index: 3;
}
.site_top_panel .row_text_search{
    font-size: 16px;
    flex-grow: 100;
    -webkit-flex-grow: 100;
    -moz-flex-grow: 100;
}
@media screen and ( max-width:479px ){
    .site_top_panel .container>*:not(.site_top_panel_toggle){
        width: 100%;
        text-align: center;
    }
    .site_top_panel #links{
        text-align: center;
    }
}
.site_top_panel .cws_social_links{
    z-index: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.site_top_panel .cws_social_links>*,
.site_top_panel .share-toggle-button{
    display: inline-block;
    font-size: 19px;
    line-height: 19px;
    width: 19px;
    height: 19px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    color:  #fff;
    border-radius: 50%;
    padding: 7px;
    margin-top: -16px !important;
    margin-left: -16px !important;
    -webkit-transition: all 0s;
    -moz-transition: all 0s;
    -ms-transition: all 0s;
    transition: all 0s;
}

.site_top_panel .lang_bar{
    display: inline-block;
    vertical-align: middle;
}
.site_top_panel .lang_bar ul ul{
    top: 100%;
    bottom: auto;
}
.site_top_panel .lang_bar ul li{
    text-align: left;
}
.site_top_panel  .lang_bar ul ul:before{
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    background: rgba(255, 255, 255, 1);
}
.site_top_panel .mini-cart{
    display: inline-block;
    font-size: 19px;
    line-height: 19px;
    width: 18px;
    height: 18px;
    text-align: center;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    background-color: #fff;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
    margin: 0 !important;
    vertical-align: middle;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
}
.site_top_panel #links{
    position: relative;
    z-index: 2;
    white-space: nowrap;
    flex-grow: 1;
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    text-align: right;
}
@media screen and ( max-width:479px ){
    .site_top_panel #links{
        text-align: right;
    }
}
.site_top_panel .links .solienlac {
    line-height: 32px;
    top: 50%;
    margin-top: -20px;
    margin-left: -40px;
    z-index: 1;
    cursor: pointer;
}
#links_wrapper{
    display: inline-block;
    height: 32px;
    vertical-align: middle;
    position: relative;
    z-index: 1;
    margin-right: 5px;
}


@include media-max-width($screen-xs-max) {
    .site-header {
        background-color: rgba($color-black, 0.1);
        .show-menu {
            color: $color-orange;
            font-size : 30px;
            width : 50px;
            height : 50px;
            text-align: center;
            line-height : 50px;
            cursor : pointer;
        }
        > .logo {
            @include center-all();
            img {
                max-height: 50px;
            }
        }
    }
}
