.my-navbar {
    @include lib-css(transition, all 0.3s ease-in-out, 1);
    ul {
        margin  : 0;
        padding : 20px 0;
        display : table;
        width   : 100%;
        @include lib-css(transition, all 0.3s ease-in-out, 1);
        //background-image: url('../images/header-line.png');
        //background-repeat: no-repeat;
        //background-position: center center;
        //background-size: 100% auto;
    }
    li {
        list-style     : none;
        display        : table-cell;
        vertical-align : middle;
        width          : 1%;
        text-align     : center;
        //text-transform : uppercase;
        @include lib-css(transition, all 0.3s ease-in-out, 1);
        &.active a {
            color       : $color-red;
            font-weight : 500;
        }
        &:not(.logo) a {
            border-top    : 2px solid $color-orange;
            border-bottom : 2px solid $color-orange;
            padding       : 20px 0;
        }
    }
    a {
        color           : $color-black;
        text-decoration : none;
        font-size       : 14px;
        display         : block;
        @include lib-css(transition, all 0.3s ease-in-out, 1);
        &:hover,
        &:active,
        &:focus {
            color : $color-red;
        }
    }
    img {
        max-height : 130px;
        @include lib-css(transition, all 0.3s ease-in-out, 1);
    }
    .small-header & {
        ul {
            padding : 10px 0;
        }
        li {
            &:not(.logo) a {
                padding : 10px 0;
            }
        }
        img {
            max-height : 120px;
        }
    }
}

@include media-max-width($screen-lg-max) {
    .my-navbar {
        li {
            width: percentage(1px/7px);
        }
        img {
            max-height : 150px;
        }
        a {
            font-size : 14px;
        }
        li:not(.logo) a {
            padding : 12px 0;
        }
        .small-header & {
            ul {
                padding : 5px 0;
            }
            li {
                &:not(.logo) a {
                    padding : 7px 0;
                }
            }
        }
    }
}

@include media-max-width($screen-sm-max) {
    .my-navbar {
        img {
            max-height : 100px;
        }
        a {
            font-size : 12px;
        }
        li:not(.logo) a {
            padding : 5px 0;
        }
        li.active a {
            font-weight : 400;
        }
        li {
            width : 1%;
            white-space: nowrap;
        }
        .small-header & {
            ul {
                padding : 5px 0;
            }
            li {
                &:not(.logo) a {
                    padding : 5px 0;
                }
            }
            img {
                max-height: 80px;
            }
        }
    }
}

@include media-max-width($screen-xs-max) {
    .my-navbar {
        position : fixed !important;
        top : 0;
        left : 0;
        height : 100%;
        background-color: $color-white;
        margin-left : -100%;
        #menu-visible-trigger:checked ~ .site-wrapper & {
            margin-left : 0;
        }
        .hide-menu {
            width : 50px;
            height : 50px;
            right : 0;
            position : absolute;
            top : 0;
            margin: 0;
            line-height: 45px;
            text-align: center;
            font-size: 30px;
            color: $color-orange;
            cursor : pointer;
        }
        ul {
            display : flex;
            flex-wrap: wrap;
            flex-direction: column;
        }
        li {
            width: 100%;
            text-align: left;
            &.logo {
                width : 100%;
                text-align: center;
                margin-bottom : 20px;
                margin-top : 10px;
                order: -1;
                a {
                    display: inline-block;
                }
            }
            a {
                border: 0 none !important;
            }
        }
    }
}
