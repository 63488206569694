main.site-main {
    overflow : hidden;
}

.container {
    width     : 1440px;
    max-width : 100%;
    position  : relative;
    z-index   : 1;
}

ul.list-unstyled {
    margin : 0;
}

.page-part {
    min-height : 100vh;
    position   : relative;
    overflow   : hidden;
}

.w50p {
    width : 50%;
}

.section-bg {
    position   : absolute;
    height     : auto;
    z-index    : -1;
    max-height : 100%;
    &.full-width {
        min-width  : 100%;
        max-height : none;
    }
    &.full-height {
        height    : 100%;
        width     : auto;
        max-width : none;
    }
    &.bottom-left {
        bottom : 0;
        left   : 0;
    }
    &.bottom-right {
        bottom : 0;
        right  : 0;
    }
    &.top-left {
        top  : 0;
        left : 0;
    }
    &.top-right {
        right : 0;
        top   : 0;
    }
    &.center {
        @include center-all();
    }
}

.with-dragonfly-icon {
    position     : relative;
    display      : inline-block;
    padding-left : 40px;
    &:before {
        content          : '';
        display          : inline-block;
        width            : 35px;
        height           : 35px;
        font-size        : 0;
        background-image : url('../images/dragonfly-icon.png');
        left             : 0;
        @include center-y();
    }
}

.with-icon {
    span,
    img {
        display        : inline-block;
        vertical-align : bottom;
    }
}

.content-wrapper {
    display       : block;
    height        : auto;
    width         : 100%;
    margin-top    : 200px;
    margin-bottom : 50px;
    .wrapper {
        display : block;
    }
}

/*Each section content*/
#section-2 {
    .content-wrapper {
        width       : percentage(870px/1440px);
        margin-left : 0;
    }
    .section-bg {
        &.medium-dragonfly {
            left  : 21.875%;
            width : percentage(232px/1920px);
        }
        &.top-right {
            width : percentage(740px/1920px);
        }
        &.bottom-left:not(.medium-dragonfly) {
            width : percentage(427px/1920px);
        }
    }
}

#section-3 {
    .content-wrapper {

    }
    .owl-carousel-wrapper {
        max-width : 1065px;
        width     : 100%;
        margin    : 30px auto 0;
        .row {
            margin-left  : -20px;
            margin-right : -20px;
        }
        .col-sm-4 {
            padding : 0 20px;
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                margin-bottom : 40px;
            }
        }
        .item {
            overflow : hidden;
        }
    }
    .section-bg {
        &.top-left {
            width : percentage(403px/1920px);
        }
        &.bottom-right {
            width : percentage(957px/1920px);
        }
    }
}

#section-4 {
    .content-wrapper {
        width       : 65%;
        margin-left : 38%;
        margin-top  : 200px;

    }
    .ul {
        align-items: center;
    }
    .nav > li {
        width: 230px;
        height: 75px;
        margin-top: 10px;
        margin-right: 10px;
        display: inline-block;
        float:left;
    }
    /*.nav > li {
        white-space : nowrap;
    }*/
    .section-bg {
        &.bottom-left {
            width : percentage(659px/1920px);
        }
        &.bottom-right {
            width : percentage(413px/1920px);
        }
    }
}

#section-5 {
    ul {
        margin : 0 -15px;
        @include clearfix;
    }
    li {
        float   : left;
        width   : percentage(1px/3px);
        padding : 20px 15px;
        &:nth-child(3n+1) {
            clear : both;
        }
    }
    a {
        text-decoration : none;
        display         : table;
        margin : 0 auto;
        @include lib-css(transition, all 0.3s ease-in-out, 1);
        img,
        span {
            display        : table-cell;
            vertical-align : middle;
        }
        img {
            @include lib-css(transition, all 0.3s ease-in-out, 1);
        }
        span {
            padding-left : 10px;
            width : 130px;
        }
        &:hover {
            img {
                @include opacity(0.8);
            }
        }
    }
}

#section-6 {
    .content-wrapper {
        margin-top: 180px;
    }
    .news-box {
        margin : 30px -15px 0;
        .img {
            width    : 100%;
            position : relative;
            overflow : hidden;
            display  : block;
            &:before {
                content        : '';
                display        : block;
                padding-bottom : percentage(178px/287px);
                height         : 0;
            }
            img {
                min-height : 100%;
                min-width  : 100%;
                width      : auto;
                height     : auto;
                @include center-all();
            }
        }
        .js-scroll-pane {
            width : 100%;
            height : 60vh;
            .wrapper {
                padding : 0 0 0 15px;
            }
        }
        ul,
        .tab-content {
            padding : 0;
        }
        ul {
            float : right;
            li {
                border-top : 1px solid transparent;
                @include lib-css(transition, all 0.3s ease-in-out, 1);
                &.active a {
                    background-color : $color-grey;
                }
                + li {
                    border-top-color : $color-darker-grey;
                }
            }
            a {
                display          : table;
                padding          : 32px 23px;
                width            : 100%;
                text-decoration  : none;
                background-color : $color-light-grey;
                @include lib-css(transition, all 0.3s ease-in-out, 1);
                &:hover {
                    background-color : $color-grey;
                    .title {
                        @include opacity(0.7);
                    }
                }
                > span {
                    display        : table-cell;
                    vertical-align : middle;
                }
                .img {
                    width : 287px;
                }
                .title {
                    padding-left : 15px;
                    font-size    : 17px;
                    width        : 100%;
                    span {
                        display : block;
                    }
                }
            }
        }
        .accordion {
            width : 100%;
            float : none;
            max-width: 750px;
            margin-left : auto;
            margin-right : auto;
            a {
                padding : 20px;
                .img {
                    width : 200px;
                }
                .title {
                    font-size : 15px;
                }
            }
            .wrapper {
                padding : 15px;
            }
        }
    }
    .section-bg {
        &.top-left {
            width : percentage(162px/1920px);
        }
        &.top-right {
            width : percentage(212px/1920px);
        }
    }
}

#section-7 {
    form {
        width  : 600px;
        margin : 50px auto 30px;
        label {
            display       : block;
            margin-bottom : 0;
        }
        .form-group {
            @include clearfix;
            &:last-child {
                margin-bottom : 0;
            }
        }
        button {
            float            : right;
            border           : 0 none;
            background-color : transparent;
            color            : $color-black;
            padding-right    : 0;
            @include lib-css(transition, all 0.3s ease-in-out, 1);
            &:hover {
                color : $color-orange;
            }
        }
        .form-control {
            font-weight      : 400;
            color            : #010101;
            height           : auto;
            padding          : 19px 12px;
            border-color     : $color-light-grey;
            background-color : $color-light-grey;
            @include lib-css(box-shadow, none, 1);
            @include lib-css(transition, all 0.3s ease-in-out, 1);
            &:active,
            &:focus,
            &:hover {
                background-color : $color-white;
                border-color     : $color-grey;
            }
        }
    }
    .footer {
        border-top  : 1px solid $color-grey;
        padding-top : 20px;
    }
    .content {
        width : 50%;
        &.left {
            float : left;
        }
        &.right {
            float      : right;
            text-align : right;
        }
    }
    .open-time {
        display : table;
        > span {
            display        : table-cell;
            vertical-align : baseline;
            + span {
                padding-left : 10px;
            }
        }
    }
    .box-socials {
        font-size     : 0;
        margin-bottom : 10px;
        li {
            display : inline-block;
            padding : 0 8px;
        }
        a {
            text-decoration : none;
            @include lib-css(transition, all 0.3s ease-in-out, 1);
            &:hover {
                @include opacity(0.8);
            }
        }
    }
    .section-bg {
        &.top-right,
        &.top-left {
            top : 5vw;
        }
        &.top-left {
            width : percentage(267px/1920px);
        }
        &.top-right {
            width : percentage(483px/1920px);
        }
    }
}

@include media-max-width($screen-xlg-max) {
    #section-3 .owl-carousel-wrapper {
        max-width: 800px;
    }
    #section-4 .content-wrapper {
        width : 55%;
        //margin-top : 200px;
        overflow-wrap: break-word;
    }
}

@include media-max-width($screen-md-max) {
    #section-1 {
        min-height : 50vh;
    }
    #section-6 .news-box {
        .w50p {
            display : none;
        }
        .accordion {
            display : block !important;
        }
    }
}

@include media-max-width($screen-sm-max) {
    #section-2 .content-wrapper {
        width : 80%;
        margin-top : 20vw;
        margin-left : 10%;
    }
    #section-4 .content-wrapper {
        width : 70%;
        margin-left : 20%;
        margin-top : 0;
    }
    #section-3 .content-wrapper {
        margin-top : 130px;
    }
    #section-6 {
        .content-wrapper {
            margin-top : 120px;
        }
        .news-box {
            margin-top : 20px;
        }
    }
}

@include media-max-width($screen-xs-max) {
    .with-icon {
        img {
            display : none;
        }
    }
    #section-2 .content-wrapper {
        width : 100%;
        //margin-top : 150px;
        margin-left : 0;
    }
    #section-3 .owl-carousel-wrapper .col-sm-4 {
        margin-bottom : 15px !important;
    }
    #section-4 .content-wrapper {
        width: 100%;
        margin-left: 0;
        //margin-top: 85px;
    }
    #section-5 {
        .content-wrapper {
            margin-top : 100px;
        }
        li {
            width : 50%;
            clear : none !important;
        }
        a {
            display : block;
            text-align: center;
            img {
                display : inline-block;
            }
            span {
                display : block;
                padding : 0;
                width : auto;
            }
        }
    }
    #section-6 {
        .content-wrapper {
            margin-top : 80px;
        }
        .accordion {
            a {
                padding : 10px !important;
                display : block;
                span {
                    display : block;
                    width : 100% !important;
                }
                .title {
                    padding-left : 0 !important;
                }
            }
        }
    }
    #section-7 {
        .content-wrapper {
            margin-top : 100px;
        }
        form {
            width : 100%;
            margin-top : 30px;
        }
        .content {
            width : 100%;
            float : none;
            &.right {
                text-align: left;
                margin-top : 30px;
            }
            &.left {
                word-break: break-all;
            }
        }
    }

    #section-4 {
        .nav > li {
            width: 100%;
            height: 100%;
        }
    }
}
